import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  clientAddEmail,
  createClient,
  deleteEmail,
  fetchClientEmails,
  fetchClients,
} from './async_thunks';
import { Client, ClientState, PlanObjectForPlanIdsArray } from './types';

const initialState: ClientState = {
  list: [],
  client: undefined,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setClient: (state, action: PayloadAction<Client | undefined>) => {
      state.client = action.payload;
    },
    setClients: (state, action: PayloadAction<Client[] | undefined>) => {
      state.list = action.payload;
    },
    setClientPlanIds: (
      state,
      action: PayloadAction<PlanObjectForPlanIdsArray[]>,
    ) => {
      if (state.client?.planIds) {
        state.client.planIds = action.payload;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(fetchClientEmails.fulfilled, (state, action) => {
        if (state.client) {
          state.client.clientEmails = action.payload;
        }
      })
      .addCase(deleteEmail.fulfilled, (state, action) => {
        if (state.client) {
          state.client.clientEmails = action.payload;
        }
      })
      .addCase(clientAddEmail.fulfilled, (state, action) => {
        if (state.client) {
          state.client.clientEmails = action.payload;
        }
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.client = action.payload;
      });
  },
});

export const { setClient, setClients, setClientPlanIds } = clientSlice.actions;

export default clientSlice.reducer;
