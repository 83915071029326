import { Button, Col, notification, Row, Switch } from 'antd';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import FormInputNumberField from '../../../../foundation/components/form_input_number_field/FormInputNumberField';
import { FieldType } from '../../../../foundation/components/form_modal/form_modal_types';
import FormSelectField from '../../../../foundation/components/form_select_field/FormSelectField';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import formFieldRenderer, {
  MonthFieldRenderer,
} from '../../../../foundation/utils/formUtils';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import {
  fetchSellValues,
  sellProperty,
  undoSell,
} from '../../redux/async_thunks';
import {
  selectPlanProperties,
  selectPropertyIsSold,
  selectPropertySellValues,
  selectSellFeatureCGTDiscountOptions,
} from '../../redux/selectors';
import { setPlanProperties } from '../../redux/slice';
import { useSellFields } from '../field_arrays';
import DeactivateConfirmModal from './DeactivateConfirmModal';
import validationSchema from './validation_schema';

const SellForm = () => {
  const { isDesktopViewport } = useViewport();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { id: propertyId }: any = useParams();

  const [isDeactivateSellModalVisible, setDeactivateSellModalVisibility] =
    useState(false);

  const [isSold, setIsSold] = useState(false);

  const sellValues = useSelector(selectPropertySellValues);

  const isPropertySold = useSelector(selectPropertyIsSold);

  const properties = useSelector(selectPlanProperties);

  const user = useSelector(selectUser);

  const sellFeatureCGTDiscountOptions = useSelector(
    selectSellFeatureCGTDiscountOptions,
  );

  const sellFieldsArray = useSellFields();

  const handleFormSubmit = async (values: any) => {
    console.log(values);
  };

  const renderFormFields = useCallback(
    (
      handleChange: any,
      values: any,
      errors: any,
      handleBlur: any,
      setFieldValue: any,
      fieldsArray: FieldType[],
    ) => {
      return formFieldRenderer(
        handleChange,
        values,
        errors,
        handleBlur,
        fieldsArray,
        setFieldValue,
      );
    },
    [],
  );

  const getSellValues = async (
    selldate: string,
    discount?: number,
    isFormatted?: boolean,
  ) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);

      const saleSettlementDate = isFormatted
        ? selldate
        : dayjs(selldate).format('MM-YYYY');

      if (user?.token) {
        await dispatch(
          fetchSellValues({
            token: user?.token,
            propertyId,
            discount: discount ? discount * 100 : 0,
            selldate: saleSettlementDate,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSettlementDateChange =
    (discount: number | undefined) => (v: string) => {
      if (v) {
        getSellValues(v, discount);
      }
    };

  const updatePlanProperties = (isSold: boolean) => {
    if (properties) {
      const newProperties = properties.map((prop) => {
        if (prop.propertyId === propertyId) {
          return { ...prop, isSold };
        }
        return prop;
      });

      dispatch(setPlanProperties(newProperties));
    }
  };

  const handlePropertySell = (values: any) => async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      if (user?.token && user.user_id) {
        const data = {
          propertyId: values.propertyId,
          saleSettlementDate: values.saleSettlementDate,
          salePrice: values.salePrice,
          estSellingCosts: values.estSellingCosts,
          discountPercentage: values.discountPercentage,
          estCgt: values.estCgt,
          estBuyingCosts: values.estBuyingCosts,
          estCashReceivedFromSale: values.estCashReceivedFromSale,
          userId: user.user_id,
        };

        await dispatch(
          sellProperty({ values: data, token: user.token }),
          // @ts-ignore
        ).unwrap();
      }

      updatePlanProperties(true);

      notification.success({
        message: 'Success!',
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDeactivateSaleClick = () => {
    setDeactivateSellModalVisibility(true);
  };

  const handleDeactivateSaleCancel = () => {
    setDeactivateSellModalVisibility(false);
  };

  const handleDeactivateSell = async () => {
    if (isLoading) {
      return;
    }

    try {
      if (user?.token && user.user_id) {
        setIsLoading(true);

        const data = {
          propertyId,
          userId: user.user_id,
        };

        // @ts-ignore
        await dispatch(undoSell({ values: data, token: user.token })).unwrap();

        updatePlanProperties(false);

        notification.success({
          message: 'Success!',
          description: 'Sell deactivated successfully',
        });

        // TODO: We might have to fetch the plan properties again to remove the sold label.
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isDeactivateSellModalVisible && (
        <DeactivateConfirmModal
          handleCancel={handleDeactivateSaleCancel}
          handleSubmit={handleDeactivateSell}
          isLoading={isLoading}
        />
      )}
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          discountPercentage: 0.5,
          ...sellValues,
          sellEnabled: false,
        }}
        validationSchema={validationSchema()}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setValues,
          isValid,
          dirty,
          setFieldTouched,
        }) => {
          return (
            <>
              <Row className="info__section-title-row">
                <Col
                  offset={isDesktopViewport ? 2 : 0}
                  className="info__section-title"
                >
                  Sell?
                </Col>
                <Col offset={1}>
                  <Switch
                    checked={isPropertySold ? isPropertySold : isSold}
                    onChange={(c) => {
                      setIsSold((v) => !v);
                      setFieldTouched('saleSettlementDate', true);
                      setFieldValue('sellEnabled', c);
                    }}
                    disabled={isPropertySold}
                  />
                </Col>
              </Row>
              <Row>
                <Col offset={isDesktopViewport ? 2 : 0} xl={10} xs={24}>
                  <MonthFieldRenderer
                    formField={{
                      label: 'Sale Settlement Date',
                      name: 'saleSettlementDate',
                      key: 'saleSettlementDate',
                      type: 'month',
                      placeholder: '',
                      isRequired: true,
                      requiredMessage: 'Sale Settlement Date is required.',
                      format: 'MM-YYYY',
                      disabled: isPropertySold || !isSold,
                    }}
                    isClientView={false}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    onChange={handleSettlementDateChange(
                      values.discountPercentage,
                    )}
                  />
                </Col>
                {renderFormFields(
                  handleChange,
                  values,
                  errors,
                  handleBlur,
                  setFieldValue,
                  sellFieldsArray,
                )}
                <Col offset={isDesktopViewport ? 14 : 0} xl={10} xs={24}>
                  <FormSelectField
                    label="Apply CGT Discount"
                    onChange={(v: number) => {
                      if (values.saleSettlementDate) {
                        getSellValues(values.saleSettlementDate, v, true);
                      }
                      setFieldValue('discountPercentage', v);
                    }}
                    value={values.discountPercentage}
                    error={errors.discountPercentage}
                    options={sellFeatureCGTDiscountOptions}
                    disabled={isPropertySold || !isSold}
                  />
                </Col>
              </Row>
              <Row align={'bottom'}>
                <Col
                  offset={isDesktopViewport ? 2 : 0}
                  xl={10}
                  xs={24}
                  style={!isDesktopViewport ? { marginBottom: 10 } : {}}
                >
                  <FormInputNumberField
                    name="estCashReceivedFromSale"
                    label="Estimated Cash Received From The Sale"
                    type="number"
                    addonBefore="$"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.estCashReceivedFromSale}
                    error={errors.estCashReceivedFromSale}
                    disabled
                  />
                </Col>
                <Col offset={isDesktopViewport ? 2 : 0} xl={10} xs={24}>
                  {!isPropertySold && (
                    <Button
                      type="primary"
                      style={{
                        height: 40,
                        marginBottom: 10,
                        width: !isDesktopViewport ? '100%' : 'auto',
                      }}
                      disabled={!isValid || isLoading || !isSold}
                      onClick={handlePropertySell(values)}
                    >
                      Add to savings
                    </Button>
                  )}

                  {isPropertySold && (
                    <Button
                      type="primary"
                      style={{
                        height: 40,
                        marginBottom: 10,
                        zIndex: 5,
                        width: !isDesktopViewport ? '100%' : 'auto',
                      }}
                      onClick={handleDeactivateSaleClick}
                    >
                      Deactivate Sale
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SellForm;
