import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import {
  addItemToStorage,
  removeItemToStorage,
} from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';

export const createPlan = createAsyncThunk(
  'plan/create-plan',
  async (apiParams: { token: string; data: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypyedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Plan/create-plan`,
      encrypyedData,
      options,
    );

    const data = decryptData(response.data);

    return {
      clientId: data.clientId,
      planId: data.planId,
      planName: data.planName,
      isInternal: data.isInternal,
      projectionStartYear: data.projectionStartYear,
      planInputs: data.planInputs,
    };
  },
);

export type DuplicatePlanRequestBody = {
  sourceClientId: string;
  plans: {
    planId: string;
    destinationPlanName: string;
  }[];
  createNewDestinationClient?: boolean;
  destinationClientId?: string;
  newDestinationClientName?: string;
  movePlan: boolean;
  userId: string;
};

export const duplicatePlan = createAsyncThunk(
  'plan/duplicate-plan',
  async (apiParams: {
    token: string;
    data: DuplicatePlanRequestBody;
    picture: any;
  }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypyedData = encryptData(apiParams.data);

    const formData = new FormData();
    formData.append('e', encrypyedData);

    if (apiParams.picture) {
      formData.append('f', apiParams.picture || null);
    }

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Plan/duplicate`,
      formData,
      {
        headers: { ...options.headers, 'content-type': 'multipart/form-data' },
      },
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const fetchPlan = createAsyncThunk(
  'plan/fetch-plan',
  async (apiParams: { token: string; planId: string }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Plan/plan-inputs/${apiParams.planId}`,
      options,
    );

    const data = decryptData(response.data);

    return {
      clientId: data.clientId,
      planId: data.planId,
      planName: data.planName,
      isInternal: data.isInternal,
      projectionStartYear: data.projectionStartYear,
      planInputs: data.planInputs,
    };
  },
);

export const updatePlan = createAsyncThunk(
  'plan/update-plan',
  async (apiParams: { token: string; data: object }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Plan/plan-inputs`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    const responseData = {
      clientId: data.clientId,
      planId: data.planId,
      planName: data.planName,
      isInternal: data.isInternal,
      projectionStartYear: data.projectionStartYear,
      planInputs: data.planInputs,
    };

    addItemToStorage('plan', JSON.stringify(responseData));
    return responseData;
  },
);

export const deletePlan = createAsyncThunk(
  'plan/delete-plan',
  async (apiParams: { token: string; data: object }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Plan/delete`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    addItemToStorage('client', JSON.stringify(data.clientData));
    removeItemToStorage('plan');

    return data.clientData;
  },
);

export const editPlanName = createAsyncThunk(
  'plan/edit-plan-name',
  async (apiParams: { token: string; data: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.patch(
      `${env_constants.PP_API_BASE_URL}/Plan/rename-plan`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    const responseData = {
      clientId: data.clientId,
      planId: data.planId,
      planName: data.planName,
      isInternal: data.isInternal,
      projectionStartYear: data.projectionStartYear,
      planInputs: data.planInputs,
      planIds: data.planIds,
    };

    addItemToStorage('plan', JSON.stringify(responseData));

    return responseData;
  },
);

export const generatePlan = createAsyncThunk(
  'plan/generate-plan',
  async (apiParams: { token: string; data: object }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Plan/generate`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const getGenerateStatus = createAsyncThunk(
  'plan/get-generate-status',
  async (apiParams: { token: string; planId: string; counter: number }) => {
    try {
      const options = {
        ...getBasicAuthAPIOptions(apiParams.token),
      };

      const response = await axios.get(
        `${env_constants.PP_API_BASE_URL}/Plan/generate-status/${apiParams.planId}/${apiParams.counter}`,
        options,
      );

      const data = decryptData(response.data);

      return {
        completionRate: data.completionRate,
        progressDetails: data.progressDetails,
        status: response.status,
      };
    } catch (error: any) {
      console.log(error);
      if (error?.response && error?.response?.status === 428) {
        throw {
          type: 'PRECONDITION_REQUIRED',
          message: 'Failed initial assessment',
        };
      }

      throw error;
    }
  },
);
