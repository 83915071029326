import * as Yup from 'yup';

const UserInputsValidationSchema = () => {
  const requiredMessage = 'This field is required';
  const typeErrorMessage = 'Please enter a valid number';
  const percentageFieldRequiredMessage = 'Field must be in between 0-100';

  const fields: any = {
    financialGoal: Yup.string().required(requiredMessage).nullable(),
    targetYears: Yup.number()
      .min(1, 'Target Years must be at least 1')
      .required(requiredMessage)
      .nullable(),
    financialTarget: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Financial Target must be at least $1')
      .required(requiredMessage),
    targetYear: Yup.string().required(requiredMessage).nullable(),
    optimiseFor: Yup.string().required(requiredMessage).nullable(),
    cashAvailable: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Cash Available must be at least $1')
      .required(requiredMessage),
    yearlySavings: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Yearly Savings must be at least $1')
      .required(requiredMessage),
    borrowingCapacity: Yup.number()
      .typeError(typeErrorMessage)
      .min(310000, 'Borrowing Capacity must be at least $310,000')
      .max(
        100000000,
        'Borrowing Capacity must not be greater than $100,000,000',
      )
      .required(requiredMessage),
    loanTerm: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Loan Term must be at least 1')
      .required(requiredMessage),
    loanIoTerm: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Loan IO Term must be at least 1')
      .required(requiredMessage),
    loanPiTerm: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Loan P&I Term must be at least 1')
      .required(requiredMessage),
    maxClientPurchaseLvr: Yup.number()
      .typeError(typeErrorMessage)
      .min(0, percentageFieldRequiredMessage)
      .max(100, percentageFieldRequiredMessage)
      .required(requiredMessage),
    lmiPaymentMode: Yup.string().required(requiredMessage),
    numberOfPropertiesSignedUp: Yup.number()
      .typeError(typeErrorMessage)
      .min(1, 'Must be at least 1')
      .required(requiredMessage),
    projectStartDate: Yup.string().required(requiredMessage).nullable(),
    firstPurchaseDate: Yup.string().required(requiredMessage).nullable(),
    endOfPurchasePeriod: Yup.string().required(requiredMessage).nullable(),
    clientRiskProfile: Yup.string().required(requiredMessage).nullable(),
    cashBuffer: Yup.number()
      .typeError(typeErrorMessage)
      .min(0)
      .required(requiredMessage),
    cashBufferLowerLimit: Yup.number()
      .typeError(typeErrorMessage)
      .min(0)
      .required(requiredMessage),
    maxNegativeCashFlowYearlySavings: Yup.number()
      .typeError(typeErrorMessage)
      .min(0)
      .required(requiredMessage),
    useOfPortfolioCashFlow: Yup.boolean().required(requiredMessage),
    clientRefinancingMaxLvr: Yup.number()
      .typeError(typeErrorMessage)
      .min(0)
      .required(requiredMessage),
    maxNumberOfPropertiesSold: Yup.number()
      .typeError(typeErrorMessage)
      .min(0)
      .required(requiredMessage),
    minYearsForHoldingProperty: Yup.number()
      .typeError(typeErrorMessage)
      .min(0)
      .required(requiredMessage),
  };

  return Yup.object().shape(fields);
};

export default UserInputsValidationSchema;
