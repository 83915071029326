import { Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { selectUser } from '../../../features/authentication/redux/selectors';
import {
  fetchPlan,
  getGenerateStatus,
} from '../../../features/plan/redux/async_thunks';
import { selectPlan } from '../../../features/plan/redux/selectors';
import { fetchPlanProperties } from '../../../features/property/redux/async_thunks';
import envConstants from '../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../store/hooks';
import { addItemToStorage } from '../../utils/storageHandler';

type AutomationLoaderProps = {
  visible?: true | false;
  onClose: () => void;
};

const AutomationLoader = ({
  visible = true,
  onClose,
}: AutomationLoaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [generatePlanInfo, setGeneratePlanInfo] = useState({
    completionRate: 0,
    progressDetails: 'Plan generation started...',
  });

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  /**
   * This function fetches the current automation status from the BE and update the
   * generatePlanInfo state. This function is a recursive function and calls itself
   * untill it receives 200 from the API.
   */
  const getStatus = useCallback(async () => {
    let counter = 1;

    const fetchStatus = async () => {
      if (user?.token && plan?.planId) {
        try {
          counter += 1;

          const data = await dispatch(
            getGenerateStatus({
              token: user.token,
              planId: plan.planId,
              counter: counter,
            }),
          ).unwrap();

          /**
           * If the status is 202 then it means operation is still in progress.
           * Set the status and call the function again.
           */
          if (data.status === 202) {
            setGeneratePlanInfo({
              completionRate: data.completionRate,
              progressDetails: data.progressDetails,
            });
          } else {
            // Automation is completed. Fetch the updated plan and properties
            if (plan?.planId) {
              /**
               * Fetch the updated plan and auto generated properties.
               */
              const [updatedPlan] = await Promise.all([
                dispatch(
                  fetchPlan({ token: user.token, planId: plan?.planId }),
                ).unwrap(),
                dispatch(
                  fetchPlanProperties({
                    token: user.token,
                    planId: plan?.planId,
                  }),
                ).unwrap(),
              ]);

              // Set the generated plan to storage.
              addItemToStorage('plan', JSON.stringify(updatedPlan));
            }

            // Close the automation progress modal
            onClose();

            // Navigate the user to dashboard
            navigate('/dashboard');

            clearInterval(intervalId);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const intervalId = setInterval(
      fetchStatus,
      envConstants.PLAN_GENERATE_STATUS_INTERVAL_SECONDS * 1000,
    );

    return () => clearInterval(intervalId);
  }, [plan, navigate]);

  /**
   * Call the getStatus function
   */
  useEffect(() => {
    if (user?.token && plan?.planId) {
      getStatus();
    }
  }, [user?.token, plan?.planId]);

  // Modal props
  const modalOpts = {
    open: visible,
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
  };
  return (
    <Modal {...modalOpts} className="c-form-modal">
      <div className="c-automation-loader">
        <span className="loader"></span>
        <div>{generatePlanInfo.completionRate}% Completed</div>
        <div>{generatePlanInfo.progressDetails}</div>
      </div>
    </Modal>
  );
};

export default AutomationLoader;
